import {
    Box, Button, ButtonGroup, Container, CssBaseline,

    Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid,
    IconButton, InputLabel, MenuItem, Paper, Select, TextField,
    Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import WarningIcon from '@material-ui/icons/Warning';
import InvalidAddressDialog from 'components/InvalidAddressDialog';
import message from 'components/Message';
import RecommendedAddress from 'components/RecommendedAddress';
import React, { PureComponent } from 'react';
import http from 'utils/http';
import history from '../../utils/history';
import AddressModal from './AddressModal';
import BasketItemsView from './BasketItemsView';
import BrokerView from './BrokerView';
import CustomerLookupModal from './CustomerLookupModal';
import ShippingAddressLookupModal from './ShippingAddressLookupModal';
import SingleAddressView from './SingleAddressView';

export default class CheckoutView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            openLookupAddress: false,
            openCustomerLookup: false,
            customerName: '',
            customerNumber: '',
            openAddress: false,
            reason: '',
            additionalReason: '',
            address: null,

            inputAddress: { shippingMethodCode: 'Standard', country: 'United States' },
            validating: false,

            oboRequired: false,
            obo: null,

            shippingNotes: '',
            upsShippingNotes: '',

            showAddressCorrectedDialog: false,

            confirmShippingMethod: false,
            nextShippingMethod: null,
            nextLineId: null,
            nextIndex: null,

            newAddressTarget: null,

            totalPrices: null,

            errorMessage: null,

            addressValidated: false,

            marketSegmentOptions: [], // user.customFields.marketSegments || [],
            marketSegment: '',
            costCenter: '',
            costCenters: [],
            openInvalid: false,
            openRecommended: false,
            recommended: null
        };
    }

    componentDidMount() {
        this.setState({ totalPrices: null });
        const { getCheckoutItems } = this.props;
        getCheckoutItems();
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const pricingInfo = this.props.pricing || nextProps.pricing;
        const basketItems = this.props.basketItems || nextProps.basketItems;
        this.setState({ totalPrices: null });
        if (pricingInfo && basketItems && basketItems.length > 0 && basketItems.some((b) => b.customFields.program === 'OE')) {
            let total = 0;
            for (const item of basketItems) {
                const { quantity, code } = item;

                let matchedPrice;
                const itemPricing = pricingInfo.filter((p) => p.description.includes(code));
                for (const ip of itemPricing) {
                    const qtyRange = ip.quantity.indexOf('+') > 0
                        ? [parseInt(ip.quantity.replace('+', ''), 10), 99999999999]
                        : ip.quantity.split('–').map((q) => parseInt(q, 10));

                    if (item.quantity >= qtyRange[0] && item.quantity <= qtyRange[1]) {
                        matchedPrice = ip.printPricePerUnit;
                    }
                }
                if (matchedPrice) {
                    total += (matchedPrice * quantity);
                }
            }

            if (total) {
                this.setState({ totalPrices: total.toFixed(2) });
            }
        }

        const { inputAddress } = this.state;
        if (nextProps.shippingMethods && nextProps.shippingMethods.length && !nextProps.shippingMethods.some((m) => m.code === inputAddress.shippingMethodCode)) {
            this.setState({ inputAddress: { ...inputAddress, shippingMethodCode: nextProps.shippingMethods[0].code } });
        }

        const { marketSegmentXCostCenters } = this.props;
        if (nextProps.marketSegmentXCostCenters && !marketSegmentXCostCenters) {
            const { user: { customFields: { costCenters } } } = nextProps;
            const marketSegmentOptions = [...new Set(nextProps.marketSegmentXCostCenters.map((x) => x.marketSegment).filter((ms) => ms !== 'MCARE'))];
            // const { costCenter } = nextProps.marketSegmentXCostCenters[0];
            // const { marketSegment } = nextProps.marketSegmentXCostCenters[0];
            // const costCenters = nextProps.marketSegmentXCostCenters.filter((x) => x.marketSegment === marketSegment).map((x) => x.costCenter);
            if (costCenters && costCenters.length > 0) {
                // default
                const defaultValue = nextProps.marketSegmentXCostCenters.find((x) => costCenters.includes(x.costCenter.value));
                if (defaultValue) {
                    const cc = nextProps.marketSegmentXCostCenters.filter((x) => x.marketSegment === defaultValue.marketSegment).map((x) => x.costCenter);
                    this.setState({
                        marketSegmentOptions,
                        costCenter: defaultValue.costCenter.value,
                        marketSegment: defaultValue.marketSegment,
                        costCenters: cc
                    });
                } else {
                    this.setState({
                        marketSegmentOptions
                    });
                }
            } else {
                this.setState({
                    marketSegmentOptions
                });
            }
        }

        if (!this.props.basketItems && nextProps.basketItems) {
            this.refreshEstimatedDeliveryDate(nextProps.basketItems);
        }
    }

    componentDidUpdate() {
        const { getPricing, pricing } = this.props;
        if (!pricing) { getPricing(); }
    }

    refreshEstimatedDeliveryDate = (nextBasketItems) => {
        const { shippingAddresses, GetEstimatedDeliveryDate } = this.props;
        let { basketItems } = this.props;
        if (nextBasketItems) basketItems = nextBasketItems;

        if (basketItems && basketItems.length
            && basketItems[0].customFields
            && ['OE', 'Small Group'].includes(basketItems[0].customFields.program)) {
            const edd = [];
            for (const bi of basketItems) {
                const { shippings, itemType } = bi.customFields;
                for (let n = 0; n < shippings.length; n += 1) {
                    if (shippings[n].shippingAddress || shippings[n].shippingAddressData) {
                        const addr = shippings[n].shippingAddress
                            ? shippingAddresses.find((sa) => sa.id == shippings[n].shippingAddress)
                            : shippings[n].shippingAddressData;
                        edd.push({
                            lineId: bi.lineId,
                            index: n,
                            addressId: shippings[n].shippingAddress,
                            code: bi.code,
                            isKit: itemType === 'KT',
                            quantity: shippings[n].quantity,
                            state: addr.state || '',
                            country: addr.country || '',
                            shippingMethodId: shippings[n].shippingMethod,
                            program: basketItems[0].customFields.program,
                            isPOD: itemType === 'KT' ? bi.customFields.items && bi.customFields.items.some((i) => i.dp === 'Y') : bi.customFields.dp === 'Y'
                        });
                    }
                }
            }

            if (edd.length) {
                GetEstimatedDeliveryDate(edd);
            }
        }
    }

    changeShippingQty = (lineId, index, qty) => {
        this.setState({ errorMessage: null });
        const { applyShippingQty } = this.props;
        applyShippingQty(lineId, index, qty);
        const { inputAddress } = this.state;
        if (!(inputAddress && inputAddress.address)) {
            const planDocumentExceeded = this.checkPlanDocumentExceeded();
            if (planDocumentExceeded) {
                return;
            }
        }
        this.refreshEstimatedDeliveryDate();
    }

    changeMarketSegment = (e) => {
        this.setState({ marketSegment: e.target.value });
        this.setState({ costCenter: '', costCenters: [] });
        if (e.target.value) {
            const { marketSegmentXCostCenters } = this.props;
            if (marketSegmentXCostCenters) {
                const costCenters = marketSegmentXCostCenters.filter((x) => x.marketSegment === e.target.value).map((x) => x.costCenter);
                this.setState({
                    costCenters,
                    costCenter: costCenters.length === 1 ? costCenters[0] : ''
                });
            } else {
                http.get(`store/get-cost-centers/${e.target.value}`)
                    .then((costCenters) => this.setState({
                        costCenters: costCenters.map((cc) => cc.value),
                        costCenter: costCenters.length === 1 ? costCenters[0] : ''
                    }));
            }
        }
    }

    changeCostCenter = (e) => {
        this.setState({ costCenter: e.target.value });
    }

    onReasonChange = (reason) => {
        this.setState({ reason });
    }

    onShippingAddressChange = (lineId, index, addressId) => {
        if (addressId === '-1') {
            this.setState({ newAddressTarget: { lineId, index } });
            this.openAddressModal();
        } else if (addressId === '-2') {
            this.setState({ newAddressTarget: { lineId, index } });
            this.openLookupAddressModal();
        } else {
            const { applyShippingAddress } = this.props;
            applyShippingAddress(lineId, index, addressId);
        }

        this.refreshEstimatedDeliveryDate();
    }

    closeConfirmShippingMethod = () => {
        this.setState({
            nextShippingMethod: null, nextLineId: null, nextIndex: null, confirmShippingMethod: false
        });
    }

    saveConfirmShippingMethod = () => {
        const { nextShippingMethod, nextLineId, nextIndex } = this.state;
        const { applyShippingMethod } = this.props;
        applyShippingMethod(nextLineId, nextIndex, nextShippingMethod);
        this.closeConfirmShippingMethod();

        this.refreshEstimatedDeliveryDate();
    }

    onShippingMethodChange = (lineId, index, code) => {
        if (code && code !== 'UPS Ground') {
            this.setState({
                nextShippingMethod: code, nextLineId: lineId, nextIndex: index, confirmShippingMethod: true
            });
        } else {
            const { applyShippingMethod } = this.props;
            applyShippingMethod(lineId, index, code);
        }
        this.refreshEstimatedDeliveryDate();
    }

    onShippingMethodDataChange = (lineId, index, data) => {
        const { applyShippingMethodData } = this.props;
        applyShippingMethodData(lineId, index, data);
    }

    addShipping = (lineId) => {
        const { addShipping } = this.props;
        addShipping(lineId);
        this.refreshEstimatedDeliveryDate();
    }

    removeShipping = (lineId, i) => {
        const { removeShipping } = this.props;
        removeShipping(lineId, i);
        this.refreshEstimatedDeliveryDate();
    }

    closeAddressModal = () => {
        this.setState({ openAddress: false, newAddressTarget: null });
    }

    saveAddress = (addr) => {
        const { saveAddress } = this.props;
        const { newAddressTarget } = this.state;
        saveAddress(addr, newAddressTarget);
        this.setState({ openAddress: false, newAddressTarget: null });
    }

    openAddressModal = () => {
        this.setState({ openAddress: true });
    }

    closeLookupAddressModal = () => {
        this.setState({ openLookupAddress: false, newAddressTarget: null });
    }

    saveLookupAddress = (addr) => {
        const { applySelectedShippingAddress } = this.props;
        const { newAddressTarget: { lineId, index } } = this.state;
        applySelectedShippingAddress(lineId, index, addr);
        this.setState({ openLookupAddress: false, newAddressTarget: null });
        this.refreshEstimatedDeliveryDate();
    }
    openLookupAddressModal = () => {
        this.setState({ openLookupAddress: true });
    }

    placeOrder = () => {
        this.setState({ errorMessage: null });
        const {
            inputAddress, obo: oboData, oboRequired, shippingNotes, upsShippingNotes, totalPrices, addressValidated, customerNumber, customerName
        } = this.state;
        const { showMessage, user, contactInfo } = this.props;
        const { submitOrder, used } = this.props;
        if (inputAddress && inputAddress.address) {
            if (addressValidated) {

                const exceeded = this.getExceeded();

                const { reason: reasonType, additionalReason } = this.state;
                const reason = reasonType === 'Other' ? additionalReason : reasonType;
                submitOrder((exceeded ? 'Pending' : 'Approved'), reason, used, inputAddress, null, null, shippingNotes, null, null, null, upsShippingNotes, customerNumber, customerName);
            } else {
                this.setState({ validating: true });
                http.post('store/validate-address', {
                    Address1: inputAddress.address,
                    Address2: inputAddress.address2,
                    City: inputAddress.city,
                    State: inputAddress.state,
                    Zipcode: inputAddress.zip,
                    Country: inputAddress.country || 'United States'
                }).then((r) => {
                    if (r.Success) {
                        const addressR = {
                            ...inputAddress,
                            address: r.Address1,
                            address2: r.Address2,
                            city: r.City,
                            state: r.State,
                            zip: r.Zipcode
                        };

                        if (inputAddress.address !== r.Address1
                            || (!!inputAddress.address2 && !!r.Address2 && inputAddress.address2 !== r.Address2)
                            || inputAddress.city !== r.City
                            || inputAddress.state !== r.State
                            || inputAddress.zip !== r.Zipcode
                            || inputAddress.country !== r.Country
                        ) {
                            const ia = { ...inputAddress };
                            if (!inputAddress.city && r.City) {
                                ia.city = r.City;
                            }
                            if (!inputAddress.state && r.State) {
                                ia.state = r.State;
                            }
                            this.onInputAddressChange(ia);

                            this.setState({ recommended: addressR, openRecommended: true });
                            return;
                        }

                        const exceeded = this.getExceeded();

                        const { reason: reasonType, additionalReason } = this.state;
                        const reason = reasonType === 'Other' ? additionalReason : reasonType;
                        submitOrder((exceeded ? 'Pending' : 'Approved'), reason, used, inputAddress, null, null, shippingNotes, null, null, null, upsShippingNotes, customerNumber, customerName);
                    } else if (r.StatusMessage === 'Insufficient Data') {
                        let msg = 'Your address input is insufficient.';
                        if (!inputAddress.city) {
                            msg += ' Please populate City';
                        } else if (!inputAddress.state) {
                            msg += ' Please populate State';
                        }
                        message.error(msg);
                    } else {
                        this.setState({ openInvalid: true });
                    }
                }).finally(() => this.setState({ validating: false }));
            }
        } else {
            const planDocumentExceeded = this.checkPlanDocumentExceeded();
            if (planDocumentExceeded) {
                return;
            }

            const exceeded = this.getExceeded();

            const
                {
                    reason: reasonType, additionalReason, costCenter, marketSegment
                } = this.state;
            const reason = reasonType === 'Other' ? additionalReason : reasonType;
            submitOrder((exceeded ? 'Pending' : 'Approved'), reason, used, null, null, null, shippingNotes, totalPrices, costCenter, marketSegment, upsShippingNotes, customerNumber, customerName);
        }
    }

    checkPlanDocumentExceeded = () => {
        const {
            basketItems
        } = this.props;
        let planDocumentExceeded = false;
        let maxPerAddress = null;
        for (const item of basketItems) {
            if (!planDocumentExceeded && item.isPlanDocument && item.customFields.maxPerAddress) {
                maxPerAddress = maxPerAddress || item.customFields.maxPerAddress;
                for (const s of item.customFields.shippings) {
                    if (s.quantity > item.customFields.maxPerAddress) {
                        planDocumentExceeded = true;
                    }
                }
            }
        }
        if (planDocumentExceeded) {
            this.setState({ errorMessage: `Benefit Summary or Enrollment Form is limited to ${maxPerAddress} per address.` });
        }

        return planDocumentExceeded;
    }

    fallbackSrc = (e) => {
        e.target.src = '//cdndev.memberdoc.com/f/img_no_thumb.jpg';
        e.target.style.width = '60px';
    }

    saveNotes = (shippingNotes) => {
        this.setState({ shippingNotes });
    }

    saveUpsNotes = (upsShippingNotes) => {
        this.setState({ upsShippingNotes });
    }

    getExceeded = () => {
        const {
            user,
            used, basketItems
        } = this.props;
        let exceeded = false;
        if (basketItems && used && !basketItems.some((i) => i.customFields && i.customFields.program === 'Small Group')) {
            for (const item of basketItems) {
                if ((used[item.code] || 0) + item.quantity > 15) {
                    exceeded = true;
                }
            }
        }
        return exceeded;
    }

    changeAdditionalReason = (e) => {
        const additionalReason = e.target.value;
        this.setState({ additionalReason });
    }

    saveSelectedAddress = (inputAddress) => {
        this.setState({
            inputAddress, addressValidated: true, openInvalid: false, openRecommended: false, recommended: null
        });
        message.info(
            'The address has been updated, please re-Submit the order', 'bottom'
        );
    }

    onInputAddressChange = (inputAddress, addressValidated = false) => {
        this.setState({ inputAddress, validating: false, addressValidated });
    }

    onOBOChange = (obo) => {
        this.setState({ obo });
    }

    onContactInfoChange = (contactInfo) => {
        const { setContactInfo } = this.props;
        setContactInfo(contactInfo);
    }

    toggleOBO = (_, expanded) => {
        this.setState({ oboRequired: expanded });
    }

    getEstimatedDeliveryDateContent = (shippingMethods, s) => {
        const theM = shippingMethods.find((me) => me.code === s.shippingMethod);

        return theM && theM.estimatedDeliveryDate
            ? <p>Estimated delivery date: {shippingMethods.find((me) => me.code === s.shippingMethod).estimatedDeliveryDate}</p>
            : (s.estimatedDeliveryDate
                ? (<p>Estimated delivery date: {s.estimatedDeliveryDate}</p>)
                : <p />
            );
    }

    getPrice = (itemCode, itemQuantity) => {
        const { pricing } = this.props;
        if (!pricing) return null;
        const itemPricing = pricing.filter((p) => p.description.includes(itemCode));
        let prices = [];
        for (const ip of itemPricing) {
            const qtyRange = ip.quantity.indexOf('+') > 0
                ? [parseInt(ip.quantity.replace('+', ''), 10), 99999999999]
                : ip.quantity.split('–').map((q) => parseInt(q, 10));

            if (itemQuantity >= qtyRange[0] && itemQuantity <= qtyRange[1]) {
                const p = itemQuantity * ip.printPricePerUnit;
                prices = [`$${p.toFixed(2)} ${(ip.spec ? ` (${ip.spec})` : '')}`];
                // prices.push(`$${p.toFixed(2)} ${(ip.spec ? ` (${ip.spec})` : '')}`);
            }
        }
        if (prices.length) {
            return prices.map((p) => <p key={p}>{p}</p>);
        }

        return null;
    };

    goBack = (e) => {
        e.preventDefault();
        history.goBack();
        return false;
    }

    render() {
        const {
            basketItems, shippingAddresses, shippingMethods, showMessage, user, used, submitOrder, contactInfo, submitted
        } = this.props;
        if (!user) return null;
        const exceeded = this.getExceeded();

        const {
            shippingNotes, upsShippingNotes, openAddress, address, reason, additionalReason, inputAddress, validating, showAddressCorrectedDialog, confirmShippingMethod, totalPrices, errorMessage,
            openInvalid, openRecommended, recommended, openLookupAddress, openCustomerLookup, customerName, customerNumber
        } = this.state;

        const finalReason = reason === 'Other' ? additionalReason : reason;
        const allowSpouse = user && user.customFields.userType === 'telesales';

        if ((user && user.customFields && user.customFields.npn) || (basketItems && basketItems.some((i) => i.customFields && i.customFields.jobType && i.customFields.jobType === 'Broker'))) {
            return (
                <BrokerView
                    basketItems={basketItems}
                    used={used}
                    user={user}
                    submitted={submitted}
                    showMessage={showMessage}
                    submitOrder={submitOrder}
                />
            );
        }

        if (user.customFields.userType === 'telesales') {
            const requiredFieldsFilled = !!(inputAddress
                && inputAddress.firstName
                && inputAddress.lastName
                && inputAddress.phone
                && inputAddress.phone.indexOf(' ') < 0
                && inputAddress.address
                && inputAddress.zip
                && inputAddress.country);

            const merged = basketItems;//
            //     [];
            // if (basketItems && basketItems.length) {
            //     for (const item of basketItems) {
            //         if (!(m)erged.find(m => m.code == item.code)) {
            //             merged.push(item);
            //         }
            //     }
            // }
            return (

                <Container fixed className="shop-cart-view">
                    <Box component="div" mb={4}>
                        <Typography variant="h3">
                            CHECKOUT
                        </Typography>
                    </Box>
                    <Box>
                        {merged && <BasketItemsView noThumbnail basketItems={merged} />}
                    </Box>
                    <Box mt={2} mb={2} p={2} component={Paper}>
                        <SingleAddressView
                            addr={inputAddress}
                            onAddressChange={this.onInputAddressChange}
                            spouseSupported
                            showMessage={showMessage}
                            phoneRequired
                            shippingMethods={shippingMethods}
                            disableShippingMethod
                        />
                    </Box>
                    <Box>
                        <ButtonGroup className="list-btn-group">
                            <Button
                                className="btn place-order-btn"
                                disabled={(user && user.customFields && user.customFields.orderDisabled) || !requiredFieldsFilled || validating}
                                variant="contained"
                                color="primary"
                                onClick={this.placeOrder}
                            >
                                Place Order
                            </Button>
                        </ButtonGroup>
                    </Box>

                    {
                        openRecommended && (
                            <RecommendedAddress
                                open={openRecommended}
                                onClose={() => { this.setState({ recommended: null, openRecommended: false }); }}
                                recommendedAddress={recommended}
                                originalAddress={inputAddress}
                                onSave={this.saveSelectedAddress}
                            />
                        )
                    }
                    {
                        openInvalid && (
                            <InvalidAddressDialog
                                open={openInvalid}
                                onClose={() => this.setState({ openInvalid: false })}
                                onSave={() => this.saveSelectedAddress(inputAddress)}
                            />
                        )
                    }

                    <Dialog open={showAddressCorrectedDialog} onClose={() => this.setState({ showAddressCorrectedDialog: false })} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Address Corrected</DialogTitle>
                        <DialogContent>
                            The address you entered has been corrected.
                        </DialogContent>
                    </Dialog>
                </Container>
            );
        }

        const isOE = basketItems && basketItems.length && basketItems.some((b) => b.customFields && b.customFields.program === 'OE');
        const isSmallGroup = basketItems && basketItems.some((i) => i.customFields.program === 'Small Group');

        const {
            costCenters, costCenter, marketSegment, marketSegmentOptions
        } = this.state;
        const costCenterRequired = basketItems && basketItems.some((b) => b.customFields.outputType === 'Print Only' || b.customFields.outputType === "Digital and Print" || b.customFields.isPlanDocument);
        const missingCostCenter = isOE && costCenterRequired && !costCenter;
        return (
            <Container fixed className="shop-cart-view">
                <CssBaseline />
                <Box component="div" mb={4}>
                    <Typography variant="h3">
                        CHECKOUT
                    </Typography>
                </Box>
                <Box component="div">
                    <Divider style={{ height: '3px' }} />
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={1}
                        style={{ padding: '20px 0' }}
                    >
                        <Grid item xs={5}>

                            <Typography variant="h6">
                                Product
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="h6">
                                Shipping
                            </Typography>
                        </Grid>
                        <Grid item xs={5} />
                    </Grid>
                    <Divider />
                    {basketItems && basketItems.length > 0 && basketItems.map((item) => {
                        return (
                            <Grid key={item.lineId} container direction="row" justify="flex-start" alignItems="center" spacing={1} style={{ padding: '20px 0' }}>
                                <Grid item xs={5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={7}>
                                            <b>{item.customFields.alias || item.name}</b> <br />
                                            {item.code}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={7}>
                                    {item.customFields.outputType !== 'Digital Only' && item.customFields.shippings.map((s, i) => (
                                        <Grid container key={i} spacing={3}>
                                            {!item.isPlanDocument && item.originalQty && item.originalQty !== item.quantity && <Grid item xs={12}><WarningIcon style={{ color: '#FF9800' }} />Total qty of this item is {item.originalQty}</Grid>}
                                            <Grid item xs={3}>
                                                <TextField
                                                    label="Qty"
                                                    size="small"
                                                    type="number"
                                                    min={1}
                                                    inputProps={{
                                                        min: 1,
                                                        readOnly: !item.customFields.isPlanDocument && item.customFields.shippings.length === 1
                                                    }}

                                                    style={{ width: '6rem' }}
                                                    value={s.quantity}
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    onChange={(e) => this.changeShippingQty(item.lineId, i, e.target.value)}
                                                />
                                                {isOE && !item.isPlanDocument && this.getPrice(item.code, s.quantity)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel>Send To</InputLabel>
                                                    <Select
                                                        value={s.shippingAddress}
                                                        label="Send To"
                                                        onChange={(e) => this.onShippingAddressChange(item.lineId, i, e.target.value)}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Select</em>
                                                        </MenuItem>
                                                        {
                                                            shippingAddresses.map((addr) =>
                                                                <MenuItem key={addr.id} value={addr.id} disabled={addr.isPO}>
                                                                    {addr.name} {addr.isPO && (<i>(PO Box is not allowed)</i>)}
                                                                </MenuItem>
                                                            )
                                                        }
                                                        <MenuItem value="-1"><i>{'<New>'}</i></MenuItem>
                                                        {<MenuItem value="-2"><i>{'<Address Lookup>'}</i></MenuItem>}
                                                    </Select>
                                                </FormControl>
                                                {
                                                    s.shippingAddressData && (
                                                        <Box>
                                                            <Box>
                                                                {s.shippingAddressData.first1} {s.shippingAddressData.last1}
                                                            </Box>
                                                            <Box>
                                                                {s.shippingAddressData.company}
                                                            </Box>
                                                            <Box>
                                                                {s.shippingAddressData.address} {s.shippingAddressData.address2}
                                                            </Box>
                                                            <Box>
                                                                {s.shippingAddressData.city} {s.shippingAddressData.state} {s.shippingAddressData.country}
                                                            </Box>
                                                        </Box>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel>Shipping Method</InputLabel>
                                                    <Select
                                                        value={s.shippingMethod}
                                                        label="Shipping Method"
                                                        onChange={(e) => this.onShippingMethodChange(item.lineId, i, e.target.value)}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Select</em>
                                                        </MenuItem>
                                                        {
                                                            shippingMethods.map((m) => <MenuItem key={m.code} value={m.code}>{m.code}</MenuItem>)
                                                        }
                                                    </Select>
                                                    {this.getEstimatedDeliveryDateContent(shippingMethods, s)}

                                                    {s.shippingMethod === 'Estimated Delivery Date' && (
                                                        <TextField
                                                            label="Estimated Delivery Date"
                                                            required
                                                            size="small"
                                                            variant="outlined"
                                                            style={{ marginTop: '.5rem' }}
                                                            fullWidth
                                                            name="ddd"
                                                            type="date"
                                                            value={s.shippingMethodData || ''}
                                                            onChange={(e) => this.onShippingMethodDataChange(item.lineId, i, e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />

                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                >
                                                    {!(i === 0 && item.customFields.shippings.length === 1) && (
                                                        <IconButton
                                                            aria-label="RemoveIcon"
                                                            aria-describedby="remove"
                                                            variant="contained"
                                                            onClick={() => this.removeShipping(item.lineId, i)}
                                                        >
                                                            <RemoveIcon className="action-btn remove-icon" />
                                                        </IconButton>
                                                    )}
                                                    {(!(item.quantity == item.customFields.shippings.length) || item.customFields.isPlanDocument) && (
                                                        <IconButton
                                                            aria-label="AddIcon"
                                                            aria-describedby="add"
                                                            variant="contained"
                                                            onClick={() => this.addShipping(item.lineId)}
                                                        >
                                                            <AddIcon className="action-btn add-icon" />
                                                        </IconButton>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider />
                                    <Dialog
                                        open={confirmShippingMethod}
                                        onClose={this.closeConfirmShippingMethod}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">UPS Ground is Aetna’s preferred shipping method. You have selected expedited shipping at a significantly higher cost. Shipping selections are monitored and tracked for reporting purposes. Do you wish to continue?</DialogTitle>
                                        <DialogActions>
                                            <Button onClick={this.closeConfirmShippingMethod} color="primary">
                                                Cancel
                                            </Button>
                                            <Button onClick={this.saveConfirmShippingMethod} color="primary">
                                                OK
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                </Grid>
                            </Grid>
                        );
                    })}
                </Box>
                {exceeded && (!isOE) && (
                    <Grid container spacing={3}>
                        <Grid item xs={7}>
                            <Typography variant="h5">
                                Routing justification
                            </Typography>
                            <Typography variant="body1">
                                Thank you for your interest in Aetna Medicare Products. Your order exceeds the monthly allocation limit for this item. During the checkout process please explain your need for additional supplies and your local sales team will review the order. Thank you!
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>

                            <FormControl
                                variant="outlined"
                                size="small"
                            >
                                <InputLabel>Select Reason</InputLabel>

                                <Select
                                    required
                                    value={reason}
                                    style={{ width: '15rem' }}
                                    label="Select Reason"
                                    size="small"
                                    onChange={(e) => this.onReasonChange(e.target.value)}
                                >
                                    <MenuItem value="">
                                        <em>Select</em>
                                    </MenuItem>
                                    <MenuItem value="Community or large event">
                                        Community or large event
                                    </MenuItem>
                                    <MenuItem value="Replenish stock">
                                        Replenish stock
                                    </MenuItem>
                                    <MenuItem value="Initial stock not received">
                                        Initial stock not received
                                    </MenuItem>
                                    <MenuItem value="Other">
                                        Other...
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {reason === 'Other'
                            && (
                                <Grid item xs={7}>

                                    <TextField
                                        label="Please provide a justification to support the above reason(s)"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        required
                                        size="small"
                                        value={additionalReason}
                                        onChange={this.changeAdditionalReason}
                                        variant="outlined"
                                    />
                                </Grid>

                            )}
                    </Grid>
                )}
                {(isSmallGroup || isOE) && (
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                label="Special Instruction"
                                multiline
                                fullWidth
                                rows={3}
                                column={20}
                                value={shippingNotes}
                                onChange={(e) => this.saveNotes(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Shipping Notes (printed on UPS Label)"
                                multiline
                                fullWidth
                                rows={3}
                                column={20}
                                value={upsShippingNotes}
                                onChange={(e) => this.saveUpsNotes(e.target.value)}
                                variant="outlined"
                                inputProps={{
                                    maxLength: 35
                                }}
                            />
                        </Grid>
                        {isOE && (
                            <Grid item xs={4}>
                                {totalPrices && (<Box mb={1} style={{ textAlign: 'right' }}>Total Print Price: <b>${totalPrices || ''}</b> </Box>)}
                                <Box
                                    width={2 / 5}
                                    m={1}
                                    style={{ float: 'right' }}
                                    display={(costCenterRequired ? 'block' : 'none')}
                                >
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        required
                                    >
                                        <InputLabel id="cost-center-select-outlined-label">Cost Center</InputLabel>
                                        <Select
                                            labelId="cost-center-select-outlined-label"
                                            id="cost-center-select-outlined"
                                            value={costCenter}
                                            name="costCenter"
                                            onChange={this.changeCostCenter}
                                            label="Cost Center"
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            {costCenters.map((s) => <MenuItem key={s.value} value={s.value}>{s.value}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box
                                    m={1}
                                    width={2 / 5}
                                    style={{ float: 'right' }}
                                    display={(costCenterRequired ? 'block' : 'none')}
                                >
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        required
                                    >
                                        <InputLabel id="market-segment-select-outlined-label">Market Segment</InputLabel>
                                        <Select
                                            labelId="market-segment-select-outlined-label"
                                            id="market-segment-select-outlined"
                                            value={marketSegment}
                                            name="marketSegment"
                                            onChange={this.changeMarketSegment}
                                            label="Market Segment"
                                        >

                                            <MenuItem value="">Select</MenuItem>
                                            {marketSegmentOptions.map((s) => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                                        </Select>
                                    </FormControl>

                                </Box>

                            </Grid>
                        )}
                        {
                            false && isOE && (
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item><Button onClick={() => this.setState({ openCustomerLookup: true })} variant="outlined" color="secondary">Select Customer</Button></Grid>
                                        <Grid item>
                                            Customer Name: <b>{customerName}</b><br />
                                            Customer Number: <b>{customerNumber}</b>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                )}

                {errorMessage && <span style={{ color: 'red', float: 'right', fontWeight: 'bold' }}>{errorMessage}</span>}
                {isOE && missingCostCenter && <span style={{ color: 'red', float: 'right', fontWeight: 'bold' }}>Can&apos;t place order without cost center.</span>}
                <ButtonGroup className="list-btn-group">
                    <Button
                        className="btn back-btn"
                        style={{ marginRight: '1rem' }}
                        variant="contained"
                        onClick={this.goBack}
                    >Go Back
                    </Button>
                    <Button
                        className="btn place-order-btn"
                        disabled={
                            (user && user.customFields && user.customFields.orderDisabled) ||
                            (exceeded && !isOE && !finalReason)
                            || (basketItems && basketItems.some((b) => !b.customFields.isPlanDocument && b.originalQty && b.originalQty != b.quantity))
                            || (isOE && missingCostCenter)
                            || (isOE && basketItems.some(b => b.customFields.outputType !== 'Digital Only' && b.customFields.shippings.some(s => !s.shippingAddress && !s.shippingAddressData)))
                            || (!!errorMessage)
                            || submitted
                            || (false && isOE && (!customerNumber && !customerName))
                        }
                        variant="contained"
                        color="primary"
                        onClick={this.placeOrder}
                    >
                        Place Order
                    </Button>
                </ButtonGroup>

                {openAddress && (
                    <AddressModal
                        open={openAddress}
                        isOE={isOE}
                        address={address}
                        closeAddress={this.closeAddressModal}
                        saveAddress={this.saveAddress}
                        showMessage={showMessage}
                        allowSpouse={allowSpouse}
                    />
                )}

                {openLookupAddress && (
                    <ShippingAddressLookupModal
                        open={openLookupAddress}
                        handleClose={this.closeLookupAddressModal}
                        setShippingAddress={this.saveLookupAddress}
                    />
                )}

                {openCustomerLookup && (
                    <CustomerLookupModal
                        open={openCustomerLookup}
                        handleClose={() => this.setState({ openCustomerLookup: false })}
                        setCustomer={(c) => {
                            this.setState({ customerName: c.customerName, customerNumber: c.customerNumber });
                        }}
                    />
                )}
            </Container>
        );
    }
}
